import React from "react"

export default ({ label, onSelect, values }) => (
  <div class="col-span-2">
    <div class="dropdown inline-block relative w-full">
      <button class="bg-gray-300 w-full text-gray-700 font-semibold py-2 px-4 rounded inline-flex justify-center items-center">
        <span class="mr-1">{label ? label : "Choose a track"}</span>
        {!label && <i class="las la-arrow-down animate-bounce"></i>}
      </button>
      <ul class="dropdown-menu divide-y-2 absolute hidden text-gray-700 pt-1 w-full">
        {values.map(value => (
          <li class="">
            <button
              class="w-full rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4"
              href="#"
              onClick={() => onSelect(value)}
            >
              {value}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
)
