import React, { useState } from "react"
import { connect } from "react-redux"
import Layout from "../../components/Layout"
import TrackSelect from "../../components/TrackSelect"

const Track = ({ musicReadyCount, audioReadyCount }) => {
  const [music, setMusic] = useState(false)
  const [musicPrepped, setMusicPrepped] = useState(false)
  function sendMusic() {
    // window.socket.emit("conductor-music")
    setMusicPrepped(true)
  }

  function startTheMusic() {
    // window.socket.emit("conductor-start")
  }

  return (
    <Layout>
      <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 sm:col-span-1  bg-teal-200 p-5 rounded text-teal-800 text-center flex items-center justify-center">
        <i class="las la-volume-up text-2xl mr-2"></i>
        {audioReadyCount} audio ready
      </div>
      <div
        className={`col-span-2 sm:col-span-1 ${
          audioReadyCount === musicReadyCount
            ? "bg-teal-200 text-teal-800"
            : "bg-orange-200 text-teal-800"
        } p-5 rounded  text-center flex items-center justify-center`}
      >
        <i class="las la-music text-2xl mr-2"></i>
        {musicReadyCount} music ready
      </div>
      <TrackSelect
        values={["Levels-Avicci"]}
        onSelect={setMusic}
        label={music}
      />
      {music && (
        <>
          <button
            className="col-span-2 btn-secondary"
            onClick={() => sendMusic()}
          >
            Prep the music!
          </button>
          <button
            disabled={!musicPrepped}
            className={`col-span-2 btn ${
              !musicPrepped ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => startTheMusic()}
          >
            Start the music!
          </button>
        </>
      )}
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ musicReadyCount, audioReadyCount }) => {
  return { musicReadyCount, audioReadyCount }
}

const mapDispatchToProps = dispatch => {
  return {
    conductorStart: () => dispatch({ type: "server/conductor-start" }),
    conductorMusic: e =>
      dispatch({ type: "server/conductor-music", data: { music: e } }),
  }
}

const ConnectedTrack =
  typeof window !== `undefined`
    ? connect(mapStateToProps, mapDispatchToProps)(Track)
    : Track

export default ConnectedTrack